<template>
	<div>
		<Loader v-if="loading"></Loader>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '../../../other/Loader';

export default {
	name: 'ProfileIndex',
	components: { Loader },
	data() {
		return { loading: true };
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	watch: {
		formationProgress() {
			const orthographNeedOnboarding =
				this.formationProgress && this.checkOrthographNeedOnboarding(this.formationProgress.formation);
			if (orthographNeedOnboarding) this.redirectToOrthographOnboarding(this.formationProgress.formation.user_id);
			this.redirectToDefaultDashboardView(this.formationProgress);
		},
	},
	mounted() {
		const orthographNeedOnboarding =
			this.formationProgress && this.checkOrthographNeedOnboarding(this.formationProgress.formation);
		if (orthographNeedOnboarding) this.redirectToOrthographOnboarding(this.formationProgress.formation.user_id);
		this.redirectToDefaultDashboardView(this.formationProgress);
	},
	methods: {
		redirectToParcoursDetail(formationProgress, ongoingParcours, ongoingCategory) {
			if (ongoingParcours !== null && ongoingCategory == null) {
				this.$router.push({ name: 'Parcours Detail', params: { idParcours: ongoingParcours } });
				return;
			}

			const lastPassedParcoursItem = formationProgress.config.parcours_list.sort((a, b) => b.position - a.position)[0];
			if (lastPassedParcoursItem == null) return console.error('Couldnt find redirect');
			this.$router.push({ name: 'Parcours Detail', params: { idParcours: lastPassedParcoursItem.parcours_id._id } });
		},
		redirectToDefaultDashboardView(formationProgress) {
			if (formationProgress == null) return;

			let ongoingParcours = null;
			let ongoingCategory = null;
			if (formationProgress.formation.parcours_id != null) ongoingParcours = formationProgress.formation.parcours_id;
			if (formationProgress.formation.last_category != null)
				ongoingCategory = formationProgress.formation.last_category;

			if (ongoingCategory !== null && ongoingParcours !== null) {
				this.$router.push({
					name: 'Category Detail',
					params: { idParcours: ongoingParcours, idCategory: ongoingCategory },
				});
				return;
			}

			this.redirectToParcoursDetail(formationProgress, ongoingParcours, ongoingCategory);
		},
		checkOrthographNeedOnboarding(formation) {
			if (formation.for_session !== true && formation.onboarding_done !== true) return true;
			return false;
		},
		redirectToOrthographOnboarding(userId) {
			this.$router.push({ name: 'Onboarding', params: { idUser: userId } });
		},
	},
};
</script>

<style lang="scss" scoped></style>
